//TODO refacto in stimulus
const callToActionButtons = document.querySelectorAll('[data-trigger-display]');

callToActionButtons?.forEach((btn) => {
    btn.addEventListener('click', (e) => {
        const target = document.getElementById(btn.dataset.triggerDisplay);
        target?.classList.remove('hidden');
        // nodeNames are uppercase in the DOM - not types
        if (
            'LABEL' === e.target.nodeName &&
            'radio' === e.target.control.type
        ) {
            e.target.control.checked = true;
            e.target.checked = true;
        }
        if (e.target.value === '0') {
            hide(target);
        }
    });
});

function hide(target) {
    target.classList.add('hidden');

    // If it is used to hide collection of form
    if (target.hasAttribute('data-collection-holder-class')) {
        const formCollection = document.getElementById(
            target.dataset.collectionHolderClass,
        );

        let inputs = [
            ...formCollection.querySelectorAll('input'),
            ...formCollection.querySelectorAll('select'),
            ...formCollection.querySelectorAll('textarea'),
        ];

        inputs.forEach((input) => {
            input.value = '';
        });

        formCollection?.classList.add('hidden');
        formCollection.dataset.index = '1';
    }
}
