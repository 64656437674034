import tippy from 'tippy.js';

const tooltips = document.querySelectorAll('.tooltip');

for (let tooltip of tooltips) {
    tippy(tooltip, {
        content: tooltip.dataset.tooltip,
        allowHTML: true,
        placement: 'right',
        animation: 'shift-away',
        interactive: true, // allows for copy pasting the tooltip
        theme: 'dkt',
    });
}

const bookingTooltips = document.querySelectorAll('.booking-tooltip');

for (let tooltip of bookingTooltips) {
    tippy(tooltip, {
        content: tooltip.dataset.tooltip,
        allowHTML: true,
        placement: 'bottom',
        animation: 'shift-away',
        interactive: true, // allows for copy pasting the tooltip
    });
}
